import React, { useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import "../styles/apps/QRGen.css";

const QRGen = () => {
    const [qrType, setQrType] = useState("URL");
    const [inputText, setInputText] = useState("");
    const [vcardInfo, setVcardInfo] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        address: "",
    });
    const [wifiConfig, setWifiConfig] = useState({
        ssid: "",
        password: "",
        encryption: "WPA",
    });

    const [qrColor, setQrColor] = useState("#000000");
    const [qrBgColor, setQrBgColor] = useState("#ffffff");
    const [isBgTransparent, setIsBgTransparent] = useState(false);
    const [downloadFormat, setDownloadFormat] = useState("png");

    const qrSize = 300; // Fixed size of 300px

    const handleQrTypeChange = (e) => {
        setQrType(e.target.value);
        setInputText(""); // Clear text input when switching types
    };

    const handleInputChange = (e) => {
        setInputText(e.target.value);
    };

    const handleVcardChange = (e) => {
        setVcardInfo({ ...vcardInfo, [e.target.name]: e.target.value });
    };

    const handleWifiConfigChange = (e) => {
        setWifiConfig({ ...wifiConfig, [e.target.name]: e.target.value });
    };

    const generateVcardData = () => {
        const { firstName, lastName, phone, email, address } = vcardInfo;
        return `BEGIN:VCARD
VERSION:3.0
FN:${firstName} ${lastName}
TEL:${phone}
${email ? `EMAIL:${email}\n` : ""}${address ? `ADR:${address}\n` : ""}END:VCARD`;
    };

    const generateWifiData = () => {
        const { ssid, password, encryption } = wifiConfig;
        return `WIFI:S:${ssid};T:${encryption};P:${password};;`;
    };

    const handleDownload = () => {
        const canvas = document.querySelector("canvas");
        const link = document.createElement("a");
        link.download = `qrcode.${downloadFormat}`;
        link.href = canvas.toDataURL(`image/${downloadFormat}`);
        link.click();
    };

    const isQrDataValid = () => {
        if (qrType === "URL") {
            return inputText.trim().length > 0;
        }
        if (qrType === "Text") {
            return inputText.trim().length > 0;
        }
        if (qrType === "VCARD") {
            const { firstName, lastName, phone } = vcardInfo;
            return (
                firstName.trim().length > 0 &&
                lastName.trim().length > 0 &&
                phone.trim().length > 0
            );
        }
        if (qrType === "WiFi") {
            const { ssid, password } = wifiConfig;
            return ssid.trim().length > 0 && password.trim().length > 0;
        }
        return false;
    };

    const qrValue =
        qrType === "VCARD"
            ? generateVcardData()
            : qrType === "WiFi"
                ? generateWifiData()
                : inputText;

    return (
        <div id="qr-gen" className="container">
            <h1 className="heading">QR Code Generator</h1>
            <div className="input-container">
                <label className="label">Select QR Code Type:</label>
                <select
                    value={qrType}
                    onChange={handleQrTypeChange}
                    className="qr-type-select"
                >
                    <option value="URL">URL</option>
                    <option value="VCARD">VCARD</option>
                    <option value="Text">Text</option>
                    <option value="WiFi">WiFi</option>
                </select>

                {qrType === "URL" && (
                    <input
                        type="text"
                        placeholder="Enter URL"
                        value={inputText}
                        onChange={handleInputChange}
                        className="text-input"
                    />
                )}
                {qrType === "Text" && (
                    <textarea
                        placeholder="Enter text"
                        value={inputText}
                        onChange={handleInputChange}
                        className="text-input"
                    />
                )}
                {qrType === "VCARD" && (
                    <div>
                        <input
                            type="text"
                            placeholder="First Name"
                            name="firstName"
                            value={vcardInfo.firstName}
                            onChange={handleVcardChange}
                            className="text-input"
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            name="lastName"
                            value={vcardInfo.lastName}
                            onChange={handleVcardChange}
                            className="text-input"
                        />
                        <input
                            type="text"
                            placeholder="Phone Number"
                            name="phone"
                            value={vcardInfo.phone}
                            onChange={handleVcardChange}
                            className="text-input"
                        />
                        <input
                            type="email"
                            placeholder="Email (Optional)"
                            name="email"
                            value={vcardInfo.email}
                            onChange={handleVcardChange}
                            className="text-input"
                        />
                        <input
                            type="text"
                            placeholder="Address (Optional)"
                            name="address"
                            value={vcardInfo.address}
                            onChange={handleVcardChange}
                            className="text-input"
                        />
                    </div>
                )}
                {qrType === "WiFi" && (
                    <div>
                        <input
                            type="text"
                            placeholder="SSID"
                            name="ssid"
                            value={wifiConfig.ssid}
                            onChange={handleWifiConfigChange}
                            className="text-input"
                        />
                        <input
                            type="text"
                            placeholder="Password"
                            name="password"
                            value={wifiConfig.password}
                            onChange={handleWifiConfigChange}
                            className="text-input"
                        />
                        <select
                            name="encryption"
                            value={wifiConfig.encryption}
                            onChange={handleWifiConfigChange}
                            className="qr-type-select"
                        >
                            <option value="WPA">WPA/WPA2</option>
                            <option value="WEP">WEP</option>
                            <option value="">None</option>
                        </select>
                    </div>
                )}
            </div>

            <div className="input-container">
                <div className="checkbox-container">
                    <label htmlFor="transparent-bg" className="label">Transparent Background</label>
                    <input
                        type="checkbox"
                        id="transparent-bg"
                        checked={isBgTransparent}
                        onChange={() => setIsBgTransparent(!isBgTransparent)}
                        className="styled-checkbox"
                    />
                </div>
                <label className="label">Background Color:</label>
                <input
                    type="color"
                    value={qrBgColor}
                    onChange={(e) => setQrBgColor(e.target.value)}
                    className="text-input"
                    disabled={isBgTransparent}
                />
                <label className="label">Foreground Color:</label>
                <input
                    type="color"
                    value={qrColor}
                    onChange={(e) => setQrColor(e.target.value)}
                    className="text-input"
                />
            </div>

            {isQrDataValid() && (
                <div className="qr-code">
                    <QRCodeCanvas
                        value={qrValue}
                        size={qrSize}
                        bgColor={isBgTransparent ? "rgba(0,0,0,0)" : qrBgColor}
                        fgColor={qrColor}
                        includeMargin={true}
                    />
                </div>
            )}

            <div className="input-container">
                <label className="label">Select Download Format:</label>
                <select
                    value={downloadFormat}
                    onChange={(e) => setDownloadFormat(e.target.value)}
                    className="download-select"
                >
                    <option value="png">PNG</option>
                    <option value="jpg">JPG</option>
                </select>
                <button
                    onClick={handleDownload}
                    className="button"
                    disabled={!isQrDataValid()}
                >
                    Download QR Code
                </button>
            </div>
        </div>
    );
};

export default QRGen;